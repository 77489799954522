<template>
  <div id="request">
    <v-toolbar flat height="auto" :class="{ 'pt-2': $vuetify.breakpoint.xs }">
      <v-row dense>
        <v-col cols="10" sm="12" md="6">
          <v-text-field :value="filter.search" @change="value => filter.search = value" placeholder="例：商品ページ修正" class="" persistent-placeholder dense clearable hide-details filled rounded prepend-inner-icon="mdi-magnify"></v-text-field>
        </v-col>
        <v-col cols="2" v-if="$vuetify.breakpoint.xs">
          <v-menu :close-on-content-click="false" max-width="90vw" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon>
                <v-icon size="20" color="green" v-if="isFilterSelect()">mdi-filter-menu</v-icon>
                <v-icon size="20" v-else>mdi-filter-menu</v-icon>
              </v-btn>
            </template>

            <v-card class="filter-menu">
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <!-- タスク所有者 -->
                  <v-col cols="12" class="mb-2">
                    <v-select dense clearable filled rounded hide-details autofocus class="mx-1" v-model="filter.assigned_login_id" :items="post_users" item-text="name" item-value="id" placeholder="タスク所有者" persistent-placeholder></v-select>
                  </v-col>
                  <!-- 依頼者 -->
                  <v-col cols="12" class="mb-2">
                    <v-select dense clearable filled rounded hide-details autofocus class="mx-1" v-model="filter.login_id" :items="post_users" item-text="name" item-value="id" placeholder="依頼者" persistent-placeholder></v-select>
                  </v-col>
                  <!-- ステータス -->
                  <v-col cols="12">
                    <v-select dense clearable filled rounded hide-details autofocus class="mx-1" v-model="filter.status_id" :items="selectStatus" item-text="status" item-value="id" placeholder="ステータス" persistent-placeholder></v-select>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-menu>
        </v-col>
        <v-col col="4" md="2" v-if="!$vuetify.breakpoint.xs">
          <v-select dense clearable filled rounded hide-details class="mx-1" v-model="filter.assigned_login_id" :items="post_users" item-text="name" item-value="id" placeholder="タスク所有者" persistent-placeholder></v-select>
        </v-col>
        <v-col col="4" md="2" v-if="!$vuetify.breakpoint.xs">
          <v-select dense clearable filled rounded hide-details class="mx-1" v-model="filter.login_id" :items="post_users" item-text="name" item-value="id" placeholder="依頼者" persistent-placeholder></v-select>
        </v-col>
        <v-col col="4" md="2" v-if="!$vuetify.breakpoint.xs">
          <v-select dense clearable filled rounded hide-details persistent-placeholder class="ml-1" v-model="filter.status_id" :items="selectStatus" item-text="status" item-value="id" placeholder="ステータス"></v-select>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-container class="max-w-none">
      <v-row>
        <v-col md="12">
          <v-data-table :hide-default-header="$vuetify.breakpoint.xs" :headers="headers" :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100, 250, 500, 1000], showFirstLastPage: true }" :items="requests" :options.sync="options" :server-items-length="totalPassengers" :loading="loading" class="clickable" @click:row="changeView">
            <template v-slot:item.submit_login_id="{ item }">
              <template v-if="item.submit_login">{{ item.submit_login.name }}</template>
            </template>
            <template v-slot:[`item.assigned_login_id`]="{ item }">
              <template v-if="item.assigned_login">
                <div style="white-space: nowrap;">
                  <LoginAvatar :user="item.assigned_login" :size="32" :avatarClass="['d-inline-block','mr-1']" />
                  {{ item.assigned_login.name }}
                </div>
              </template>
            </template>
            <template v-slot:item.current_status_id="{ item }">
              <div v-if="item.current_status" class="caption">{{ item.current_status.status }}</div>
              <v-progress-linear rounded v-if="item.current_status_id === 8" color="warning" :value="0"></v-progress-linear>
              <v-progress-linear rounded v-else :value="getStepPercentage(item.current_status_id)" color="secondary"></v-progress-linear>
            </template>
            <template v-slot:item.id="{ item }" >
              <v-chip small v-if="item.current_status_id === 8" color="grey lighten-4">#{{ item.id }}</v-chip>
              <v-chip small v-else color="secondary">#{{ item.id }}</v-chip>
            </template>
            <template v-slot:item.title="{ item }">{{ item.subject }}</template>
            <template v-slot:item.point_total="{ item }">
              {{ item.point_total }}<span class="unit" v-show="item.point_total != null">Pt</span>
            </template>
            <template v-slot:item.release_date="{ item }">
              {{ item.release_date | moment("YYYY/MM/DD(ddd)") }} {{ item.release_time }}
            </template>
            <!-- NOTE: ▼▼▼▼▼▼▼▼▼ スマホ時のみの要素 ▼▼▼▼▼▼▼▼▼ -->
            <template v-slot:item="{ item }" v-if="$vuetify.breakpoint.xs">
              <tr class="v-data-table__mobile-table-row" @click="changeView(item)" style="padding-top: 12px;">
                <td class="v-data-table__mobile-row">
                  <div>
                    <div class="sp-table__id">
                      <v-chip small v-if="item.current_status_id === 8" color="grey lighten-4">#{{ item.id }}</v-chip>
                      <v-chip small v-else color="secondary">#{{ item.id }}</v-chip>
                    </div>
                    <div class="sp-table__subject">
                      {{ item.subject }}
                    </div>
                  </div>
                </td>
                <td class="v-data-table__mobile-row">
                  <div>
                    公開希望日：{{ item.release_date | moment("YYYY/MM/DD(ddd)") }} {{ item.release_time }}
                  </div>
                  <div class="sp-table__status">
                    <div v-if="item.current_status" class="caption">{{ item.current_status.status }}</div>
                    <v-progress-linear rounded v-if="item.current_status_id === 8" color="warning" :value="0"></v-progress-linear>
                    <v-progress-linear rounded v-else :value="getStepPercentage(item.current_status_id)" color="secondary"></v-progress-linear>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import LoginAvatar from '@/components/LoginAvatar.vue'
import axios from '@/plugins/axios'

export default {
  name: 'request',
  components: {
    LoginAvatar
  },
  data: function () {
    return {
      key: {
        altKeyOn: false,
        shiftKeyOn: false,
        ctrlKeyOn: false,
        metaKeyOn: false
      },
      filter: JSON.parse(sessionStorage.getItem('requests.filter')) || {
        search: '',
        login_id: '',
        assigned_login_id: '',
        status_id: ''
      },
      loading: true,
      totalPassengers: 0,
      options: JSON.parse(sessionStorage.getItem('requests.options')) || {
        itemsPerPage: 25,
        sortDesc: [
          true
        ]
      },
      headers: [
        {
          text: 'ステータス',
          width: 116,
          value: 'current_status_id',
          cellClass: 'cell_status'
        },
        {
          text: '依頼番号',
          value: 'id',
          width: 104,
          cellClass: 'cell_id'
        },
        {
          text: '件名',
          value: 'title',
          cellClass: 'cell_title'
        },
        {
          text: 'タスク所有者',
          value: 'assigned_login_id'
        },
        {
          text: 'Pt',
          value: 'point_total',
          align: 'end',
          width: 80
        },
        {
          text: '公開希望日',
          value: 'release_date',
          align: 'center',
          width: 128
        },
        {
          text: '依頼者',
          value: 'submit_login_id'
        }
      ],
      requests: [],
      post_users: []
    }
  },
  watch: {
    $route: {
      handler () {
        this.options.page = 1
        this.readDataFromAPI()
      }
    },
    filter: {
      handler () {
        this.options.page = 1
        this.readDataFromAPI()
      },
      deep: true
    },
    options: {
      handler () {
        this.readDataFromAPI()
      },
      deep: true
    }
  },
  computed: {
    selectStatus: function () {
      return this.$store.getters.getAllStatus
    }
  },
  methods: {
    isFilterSelect: function () {
      return this.filter.login_id || this.filter.assigned_login_id || this.filter.status_id
    },
    changeView: function (value) {
      const routeOption = { name: 'client:request_show', params: { id: value.id } }
      if (this.key.altKeyOn === true || this.key.shiftKeyOn === true || this.key.ctrlKeyOn === true || this.key.metaKeyOn === true) {
        window.open(this.$router.resolve(routeOption).href)
      } else {
        this.$router.push(routeOption)
      }
    },
    getStepPercentage: function (step) {
      if (step === 8) return 0
      var allStatus = this.$store.getters.getAllStatus
      return Math.ceil(step / (allStatus.length - 1) * 100)
    },
    readDataFromAPI: function () {
      this.loading = true
      const queryParams = {
        sort: this.options.sortBy.length === 0 ? 'id' : this.options.sortBy[0],
        direction: this.options.sortDesc.length === 0 ? 'desc' : (this.options.sortDesc[0] ? 'desc' : 'asc'),
        per_page: this.options.itemsPerPage > 0 ? this.options.itemsPerPage : 0,
        page: this.options.page
      }
      if (this.$route.params.fillter) {
        queryParams.schedule = this.$route.params.fillter
      }
      if (this.filter.search) {
        queryParams.query = this.filter.search
      }
      if (this.filter.login_id) {
        queryParams.submit_login_id = this.filter.login_id
      }
      if (this.filter.assigned_login_id) {
        queryParams.assigned_login_id = this.filter.assigned_login_id
      }
      if (this.filter.status_id) {
        queryParams.status_mst_id = this.filter.status_id
      }
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/request`, {
        params: queryParams
      })
        .then(res => {
          this.requests = res.data.data
          this.totalPassengers = res.data.total
          // 検索条件をセッションに保存
          sessionStorage.setItem('requests.filter', JSON.stringify(this.filter))
          sessionStorage.setItem('requests.options', JSON.stringify(this.options))
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  created: function () {
    const self = this

    axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/login`, {
      params: {
        per_page: 1000
      }
    })
      .then(res => {
        this.post_users = res.data.data
      })
      .catch(err => {
        console.log(err)
      })

    // キーイベント監視
    self.keyDownHandler = function (event) {
      self.key.altKeyOn = event.altKey
      self.key.shiftKeyOn = event.shiftKey
      self.key.ctrlKeyOn = event.ctrlKey
      self.key.metaKeyOn = event.metaKey
    }
    self.keyUpHandler = function (event) {
      self.key.altKeyOn = event.altKey
      self.key.shiftKeyOn = event.shiftKey
      self.key.ctrlKeyOn = event.ctrlKey
      self.key.metaKeyOn = event.metaKeyOn
    }
    window.addEventListener('keydown', self.keyDownHandler)
    window.addEventListener('keyup', self.keyUpHandler)
  },
  beforeDestroy: function () {
    window.removeEventListener('keydown', this.keyDownHandler)
    window.removeEventListener('keyup', this.keyUpHandler)
  }
}
</script>

<style scoped lang="scss">
.sp-table {
  &__id {
    margin-bottom: 4px;
  }
  &__subject {
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;width: calc(100vw - 40px - 32px);
  }
  &__status {
    width: 80px;

    &>.caption {
      text-align: center;
    }
  }
}
</style>
